<template>
    <div class="row">
        <div class="col-12" v-if="purchases.length>0">
            <transition-group name="fade" tag="div" class="row p-2">
                <div class=" col-12 col-sm-4 col-md-3 p-3" v-for="(item) in purchases" v-bind:key="item.id">
                    <router-link :to="{name:'shop.detail',params:{category:item.category_id,name:item.name}}"
                                 class="no-link" title="Show Detail">
                        <div class="card">
                            <img class="card-img-top "
                                 :src="$store.getters.rootPath+'item?image='+item.image"
                                 alt="Card image cap">
                            <div class="card-body border-top">
                                <h5 class="card-title custom-color   bold">{{item.name}}</h5>

                            </div>
                        </div>
                    </router-link>

                </div>
            </transition-group>

        </div>
        <axios :url="url" :data="{'current':current}" :trigger="trigger" type="post" v-on:success="loaded"></axios>
    </div>
</template>
<script>
    import Axios from '@/views/actions/Axios';
    import {Paths} from '@/paths';
    export default{
        props: ['current'],
        components: {Axios},
        data(){
            return {
                purchases: {},
                trigger: false,
                url: '',
            }
        },
        methods: {
            loaded(response){
                this.trigger = false;
                this.purchases = response.data;
            }
        },
        watch: {
            current(){
                this.purchases = {};
                this.trigger = true;
            }
        },
        mounted() {
            this.purchases = {};
            this.url = Paths.item.customer.last_purchase;
            this.trigger = true;
        },
    }
</script>