<template>
    <div class="row">
        <div class="col-12">
            <transition-group name="fade"
                              tag="div" class="w-100">
                <div class="position-absolute text-danger font-italic font-weight-bold " style="top:0" v-bind:key="1"
                     v-if="$store.getters.cart.items==0">
                    Your cart is empty
                </div>
                <div class="table-responsive " v-bind:key="0" v-else="">
                    <table class="table table-hover">
                        <tr>
                            <!--<th class="border-top-0 d-xs-none">#</th>-->
                            <th class="border-top-0">Item</th>
                            <th class="border-top-0">Price</th>
                            <th class="border-top-0">Quantity</th>
                            <th class="border-top-0">Total Price</th>
                            <th class="border-top-0"></th>
                        </tr>
                        <tr v-for="(item,index) in $store.getters.cart.list" v-bind:key="index">
                            <!--<th class="">{{index + 1}}</th>-->
                            <td>{{item.name}}</td>
                            <td>${{item.price}}</td>
                            <td><input type="number" :max="item.max" min="0" @change="updatePrice"
                                       v-model="item.quantity"
                                       class="form-control form-control-sm"
                                       style="width: 100px">
                            </td>
                            <td>${{(item.price * item.quantity).toFixed(2)}}</td>
                            <th><a @click.prevent="$store.dispatch('removeCartItem',item.id)" href="#"
                                   class="no-link text-danger">
                                <icon icon="trash" size="1x"></icon>
                            </a></th>
                        </tr>
                        <tr>
                            <th colspan="2">Total</th>
                            <th>{{$store.getters.cart.items}}</th>
                            <th>${{($store.getters.cart.price).toFixed(2)}}</th>
                            <th></th>
                        </tr>
                    </table>
                </div>
            </transition-group>


            <input type="button" v-if="$store.getters.cart.items>0" @click="$router.push({name:'cart.preview'})"
                   class="btn btn-sm shadow btn-outline-info mb-2 mr-2"
                   value="SEND ORDER"/>
        </div>
    </div>
</template>
<script>
    import Loader from '../../loader'
    import {Paths} from '../../paths'
    export default{
        extends: Loader,
        data(){
            return {
                success: '',

            }
        },
        methods: {

            sendOrder(){
                if (!this.$auth.check()) {
                    this.$router.push({name: 'login', query: {redirect: 'cart'}});
                    return;
                }
                let app = this;
                this.loading = true;
                this.axios.post(Paths.order.send, app.$store.getters.cart).then(() => {
                    app.showSuccessMessage("You order is received, we will process it soon");
                    app.$store.commit('clearCart');
                }).finally(() => {
                    app.loading = false;
                });
            },

            updatePrice(){
                let price = 0;
                let quantity = 0;

                for (let i = 0; i < this.$store.getters.cart.list.length; i++) {
                    price += parseFloat(this.$store.getters.cart.list[i].price) * parseInt(this.$store.getters.cart.list[i].quantity);

                    quantity += parseInt(this.$store.getters.cart.list[i].quantity);

                }

                this.$store.getters.cart.price = price;
                this.$store.getters.cart.items = quantity;
            }
        },
        mounted(){
//            this.show_delivery_information = this.$auth.check() && this.$route.name == 'cart';

        }
    }
</script>