<template>
    <div class="container-fluid pl-4 pr-4 pl-sm-5 pr-sm-5 content-container">
        <axios :url="url" :trigger="trigger" :data="{category_id:category,name:name}" v-on:success="productLoaded"
               v-on:error="invalidProduct"></axios>
        <div class="row">
            <div class="col-sm-8">
                <div class="row pr-sm-2">
                    <div class="col-12 bg-light shadow  p-3">
                        <p class="heading">Product Detail</p>
                        <hr>
                        <div class="row">
                            <div class="col-12 text-center">
                                <pulse-loader :loading="trigger" size="30px"></pulse-loader>
                            </div>
                            <transition class="fade">
                                <div class="col-12" v-if="detail!=null">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <img :src="$store.getters.rootPath+'item?image='+detail.image"
                                                 class="img-fluid img-thumbnail" alt="">
                                        </div>
                                        <div class="col-sm-8">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <tr>
                                                        <th class="border-top-0">Product name</th>
                                                        <th class="border-top-0">{{detail.name}}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Product No</th>
                                                        <th>{{detail.product_no}}</th>
                                                    </tr>
                                                    <tr v-if="detail.brand_relation!=null">
                                                        <th>Brand Name</th>
                                                        <th>{{detail.brand_relation.name}}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Individually Wrapped</th>
                                                        <th>{{detail.individually_wrapped == 0 ? 'No' : 'Yes'}}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Price per {{detail.unit_relation.name}}</th>
                                                        <th> $ {{detail.price}}</th>
                                                    </tr>
                                                    <tr>
                                                        <th colspan="2">Quantity</th>

                                                    </tr>
                                                    <tr>
                                                        <th><input type="number" class="form-control form-control-sm "
                                                                   min="1" v-model="quantity"></th>
                                                        <th><input type="button"
                                                                   class="btn btn-sm btn-outline-info shadow mr-3 mb-2"
                                                                   value="Add To Cart "
                                                                   @click="$store.commit('setCart',{
                                                                           price:detail.price,
                                                                           name:detail.name,
                                                                           id:detail.id,
                                                                           quantity:quantity,
                                                                           image:detail.image,
                                                                           max:detail.inventory_relation.quantity})">
                                                            <router-link
                                                                    :to="{name:'shop',params:{category:detail.category_id}}"
                                                                    class="btn btn-sm btn-outline-info shadow mb-2">
                                                                Back to Products
                                                            </router-link>
                                                        </th>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>

                        </div>
                    </div>
                    <div class="col-12  bg-light shadow p-3" v-if="$auth.check()">
                        <p class="heading">Last Purchases</p>
                        <hr>
                        <last-purchase v-if="detail!=null" :current="detail.id"></last-purchase>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 ">
                <div class="row pl-sm-2">
                    <div class="col-12 bg-light shadow p-3 " style="min-height: 200px;">
                        <p class="heading">Cart</p>
                        <hr>
                        <cart></cart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import Axios from '@/views/actions/Axios';
    import Cart from '@/views/components/Cart';
    import LastPurchase from '@/views/shop/LastPurchase';
    export default{
        props: ['category', 'name'],
        components: {Axios, Cart, LastPurchase},
        data(){
            return {
                trigger: false,
                url: '',
                detail: null,
                quantity: 1,

            }
        },
        methods: {
            productLoaded(response){
                this.trigger = false;
                this.detail = response.data;
            },
            invalidProduct(){
                this.trigger = false;
            },
            loadProduct(){
                this.trigger = true;
            }
        },
        watch: {
            name(){
                this.loadProduct();
            },
            category(){
                this.loadProduct();
            },
        },
        mounted(){
            this.url = Paths.item.load;
            this.loadProduct();
        }
    }
</script>